<template>
  <div>
    <el-card class="box-card" style="margin-right: 7px;">
      <el-row>
        <el-form class="el-form-margin0" label-width="120px">
          <el-col :span="8">
            <el-form-item label="代理商:">
              <el-input
                v-model="queryInfo.condition.agentName"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批次号:">
              <el-input
                v-model="queryInfo.condition.batchNo"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="padding-left: 50px;">
            <el-button type="primary" icon="el-icon-search" @click="toSearch"
              >查询</el-button
            >
            <el-button icon="el-icon-refresh" @click="toReset">重置</el-button>
            <el-button icon="el-icon-refresh" @click="back">返回</el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card class="box-card" style="margin: 7px 7px 7px 0;">
      <div style="margin-bottom: 7px;">
        <el-button type="primary" @click="openSettleDialog">结算</el-button>
      </div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%;margin-top: 0;"
        height="calc(100vh - 250px)"
        border
      >
        <el-table-column label="操作" fixed width="150" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="padding: 0;"
              @click="openView(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              style="padding: 0;"
              @click="toShowInvoice(scope.row)"
              >开票信息</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="agentName"
          label="代理商"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="batchNo"
          label="批次号"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="结算状态"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == '0'">未结算</el-tag>
            <el-tag v-if="scope.row.status == '1'">已结算</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="settlementType"
          label="结算性质"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="settlementAmount"
          label="应结算金额"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="settlementComboAmount"
          label="套餐结算金额"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="changeReduce"
          label="变更扣款"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="rebateReduce"
          label="回购扣款"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="acceptReduce"
          label="验收扣款"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="otherAmount~"
          label="其他结算"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="assureReduce"
          label="保险扣款"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="award"
          label="业绩奖励"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="extraSettlementInvoice"
          label="额外结算开票"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="extraSettlementNoInvoice"
          label="额外结算不开票"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="refusalCompensation"
          label="清退赔偿"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="assetChange"
          label="资产变动"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="settlementCycle"
          label="结算周期"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="payTime"
          label="付款日期"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="returnReason"
          label="退回理由"
          width="140"
          align="center"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="2"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 结算弹窗 -->
    <el-dialog
      title="批量结算"
      :visible.sync="settlementVisible"
      center
      width="800px"
    >
      <div>请选择需要结算的用户：</div>
      <div class="searchBox">
        <el-input
          placeholder="请输入用户姓名"
          prefix-icon="el-icon-search"
          v-model="settleQueryInfo.condition.customerName"
          clearable
        ></el-input>
        <el-button type="primary" @click="settleSearch">搜索</el-button>
      </div>
      <el-table
        :data="settleTableData"
        tooltip-effect="dark"
        style="width: 100%;margin-top: 0;"
        height="250px"
        border
        @selection-change="handleSelectionChange"
        ref="multipleTable"
      >
        <el-table-column type="selection" width="40" align="center" fixed>
        </el-table-column>
        <el-table-column
          prop="financeCode"
          label="金融单号"
          width="190"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="businessTypeName"
          label="业务类型"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="customerName"
          label="用户姓名"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="moduleNum"
          label="组件片数"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="accountItemName"
          label="核算名称"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="unitePrice"
          label="单价(元/板)"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="totalPrice"
          label="总计(元)"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="payPrice"
          label="本次付款金额(元)"
          width="120"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="paymentTaskNum"
          label="付款任务单据号"
          width="140"
          align="center"
        >
        </el-table-column> -->
        <el-table-column
          prop="createDate"
          label="生成时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="settleSizeChange"
        @current-change="settleCurrentChange"
        :current-page="settleQueryInfo.currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="settleTotal"
      >
      </el-pagination>
      <div class="selectedName">
        <div>已选：</div>
        <div class="customerNameBox">
          <div
            class="customerNameItem"
            v-for="(item, index) in multipleSelection"
            :key="index"
          >
            {{ item.customerName }} - {{ item.accountItemName }} - ￥{{
              item.payPrice
            }}
            <div class="delete" @click="deleteCustomerName(index)">×</div>
          </div>
        </div>
      </div>
      <div>
        <div>结算汇总：</div>
        <div class="summaryContent">
          <div>组件</div>
          <div style="color: #d71d05;">{{ moduleNumTotal }}片</div>
          <div style="color: #8c939d;">￥{{ totalPrice }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settlementVisible = false">取 消</el-button>
        <el-button type="primary" @click="toSettle">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看弹窗 -->
    <el-dialog title="查看" :visible.sync="viewVisible" width="800px">
      <div class="viewContent">
        <div class="viewTitle">结算信息</div>
        <el-row>
          <el-form label-width="140px">
            <el-col :span="12">
              <el-form-item label="结算性质：">
                <div class="viewValue">{{ viewFrom.settlementType }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批次号：">
                <div class="viewValue">{{ viewFrom.batchNo }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="代理商：">
                <div class="viewValue">{{ viewFrom.agentName }}</div>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
            <el-form-item label="结算对象：">
              <div class="viewValue">111</div>
            </el-form-item>
          </el-col> -->
            <el-col :span="24">
              <el-form-item label="应结算金额：">
                <div class="viewValue" style="width: 218.67px;">
                  ￥{{ viewFrom.settlementAmount }}
                </div>
                <div class="settleAmount">
                  <div>
                    固定结算：<span class="blue"
                      >￥{{ viewFrom.settlementAmount || 0 }}</span
                    >
                  </div>
                  <div>
                    其他结算：<span class="blue"
                      >￥{{ viewFrom.otherAmount || 0 }}</span
                    >
                  </div>
                  <div>
                    业绩奖励：<span class="blue"
                      >￥{{ viewFrom.award || 0 }}</span
                    >
                  </div>
                  <div>
                    保险扣款：<span class="red"
                      >￥{{ viewFrom.assureReduce || 0 }}</span
                    >
                  </div>
                  <div>
                    额外结算（开票）：<span class="red"
                      >￥{{ viewFrom.extraSettlementInvoice || 0 }}</span
                    >
                  </div>
                  <div>
                    额外结算（不开票）：<span class="red"
                      >￥{{ viewFrom.extraSettlementNoInvoice || 0 }}</span
                    >
                  </div>
                  <div>
                    回购扣款：<span class="red"
                      >￥{{ viewFrom.rebateReduce || 0 }}</span
                    >
                  </div>
                  <div>
                    清退赔偿：<span class="red"
                      >￥{{ viewFrom.refusalCompensation || 0 }}</span
                    >
                  </div>
                  <div>
                    资产变动：<span class="red"
                      >￥{{ viewFrom.assetChange || 0 }}</span
                    >
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="套餐结算金额：">
                <div class="viewValue" style="width: 218.67px;">
                  ￥{{ viewFrom.settlementComboAmount || 0 }}
                </div>
                <!-- <div class="settleAmount">
                <div>预付款：<span class="blue">￥14800</span></div>
                <div>达标款：<span class="blue">￥14800</span></div>
                <div>验收款：<span class="blue">￥14800</span></div>
                <div>档案款：<span class="blue">￥14800</span></div>
                <div>质保金：<span class="blue">￥14800</span></div>
                <div>变更款：<span class="red">￥14800</span></div>
              </div> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="回购预警金额：">
                <div class="viewValue">￥{{ viewFrom.hgyjAmount || 0 }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="回购暂行额度：">
                <div class="viewValue">￥{{ viewFrom.ngzxAmount || 0 }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保险扣款：">
                <div class="viewValue">￥{{ viewFrom.assureReduce || 0 }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="清退赔偿：">
                <div class="viewValue">
                  ￥{{ viewFrom.refusalCompensation || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="额外结算(开票)：">
                <div class="viewValue">
                  ￥{{ viewFrom.extraSettlementInvoice || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="额外结算(不开票)：">
                <div class="viewValue">
                  ￥{{ viewFrom.extraSettlementNoInvoice || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业绩奖励：">
                <div class="viewValue">￥{{ viewFrom.award || 0 }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="扣款凭证：">
                <img
                  v-if="viewFrom.reduceVoucher"
                  class="viewImg"
                  :src="viewFrom.reduceVoucher"
                  alt=""
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注：">
                <div class="viewValue">{{ viewFrom.remark || "无" }}</div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <div class="viewTitle">开票信息</div>
        <el-row>
          <el-form label-width="140px">
            <el-col :span="24">
              <el-form-item label="应结算金额：">
                <div class="viewValue" style="width: 218.67px;">
                  ￥{{ viewFrom.invoiceAmount || 0 }}
                </div>
                <div class="settleAmount">
                  <div>
                    套餐应开票：<span class="blue"
                      >￥{{ viewFrom.invoiceComboAmount || 0 }}</span
                    >
                  </div>
                  <div>
                    其他应开票：<span class="blue"
                      >￥{{ viewFrom.invoiceComboAmount || 0 }}</span
                    >
                  </div>
                  <div>
                    业绩奖励：<span class="blue"
                      >￥{{ viewFrom.invoiceAward || 0 }}</span
                    >
                  </div>
                  <div>
                    保险扣款：<span class="red"
                      >￥{{ viewFrom.invoiceAssureReduce || 0 }}</span
                    >
                  </div>
                  <div>
                    额外结算：<span class="red"
                      >￥{{ viewFrom.invoiceotherAmount || 0 }}</span
                    >
                  </div>
                  <div>
                    质保金应开票：<span class="blue"
                      >￥{{ viewFrom.invoiceZbAmount || 0 }}</span
                    >
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="套餐应开票：">
                <div class="viewValue">
                  ￥{{ viewFrom.invoiceComboAmount || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="其他应开票：">
                <div class="viewValue">
                  ￥{{ viewFrom.invoiceComboAmount || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="质保金应开票：">
                <div class="viewValue">
                  ￥{{ viewFrom.invoiceZbAmount || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保险扣款：">
                <div class="viewValue">
                  ￥{{ viewFrom.invoiceAssureReduce || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业绩奖励：">
                <div class="viewValue">￥{{ viewFrom.invoiceAward || 0 }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="回购应收票：">
                <div class="viewValue">
                  ￥{{ viewFrom.invoiceHgysAmount || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="清退应收票：">
                <div class="viewValue">
                  ￥{{ viewFrom.invoiceotysAmount || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资产变动应收票：">
                <div class="viewValue">
                  ￥{{ viewFrom.invoiceAssetChange || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运维商实开票：">
                <div class="viewValue">
                  ￥{{ viewFrom.invoiceYwsskAmount || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="盖章版确认单：">
                <img
                  v-if="viewFrom.invoiceGzbgrdAmount"
                  class="viewImg"
                  :src="viewFrom.invoiceGzbgrdAmount"
                  alt=""
                />
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <div class="viewTitle">付款信息</div>
        <el-row>
          <el-form label-width="140px">
            <el-col :span="12">
              <el-form-item label="经办人：">
                <div class="viewValue">
                  {{ viewFrom.payJbr || "无" }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="操作时间：">
                <div class="viewValue">
                  {{ viewFrom.payCzTime || "无" }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="付款日期：">
                <div class="viewValue">{{ viewFrom.payDay || "无" }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款银行：">
                <div class="viewValue">
                  {{ viewFrom.payBank || "无" }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="质保金应开票：">
                <div class="viewValue">
                  ￥{{ viewFrom.payZbjykpAmount || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款户名：">
                <div class="viewValue">
                  {{ viewFrom.payAccountName || "无" }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业绩奖励：">
                <div class="viewValue">￥{{ viewFrom.payAward || 0 }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款账号：">
                <div class="viewValue">
                  {{ viewFrom.payAccountNo || "无" }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="清退应收票：">
                <div class="viewValue">
                  ￥{{ viewFrom.payQtyspAmount || 0 }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="付款单位：">
                <div class="viewValue">
                  {{ viewFrom.payUnit || "无" }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注：">
                <div class="viewValue">
                  {{ viewFrom.payRemark || "无" }}
                </div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog
      title="开票信息"
      :visible.sync="invoiceVisible"
      center
      width="800px"
    >
      <el-row>
        <el-form :model="invoiceForm" ref="invoiceRules">
          <el-col :span="24">
            <el-form-item
              label="发票图片："
              :rules="{
                required: true,
                message: '请上传发票图片',
                trigger: 'blur,change'
              }"
            >
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-progress="
                  () => {
                    this.IULoading = true;
                  }
                "
                :on-success="handleimageSuccess"
                :before-upload="beforeUpload"
                :multiple="true"
              >
                <div class="imgBox" v-for="(item, index) in detailList">
                  <el-image :src="item.imageUrl" class="avatar" alt="" />
                  <div class="img-icon">
                    <i
                      class="el-icon-zoom-in"
                      @click="handleImageUrlPreview($event, index)"
                    ></i>
                    <i
                      class="el-icon-delete"
                      @click="handleImageRemove($event, index)"
                    ></i>
                  </div>
                </div>
                <i
                  v-loading="IULoading"
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer" v-if="!invoiceForm.iconUrl">
        <el-button @click="invoiceVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitInvoice()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {
          agentCode: ""
        }
      },
      settleQueryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {
          agentCode: ""
        }
      },
      tableData: [],
      settleTableData: [],
      total: 0,
      settleTotal: 0,
      settlementVisible: false, // 结算弹窗
      multipleSelection: [],
      moduleNumTotal: 0, //组件总片数
      totalPrice: 0,
      viewVisible: false,
      invoiceVisible: false,
      dialogVisible: false,
      dialogImageUrl: "",
      invoiceId: "",
      detailList: [],
      invoiceForm: {
        iconUrl: ""
      },
      IULoading: false,
      viewFrom: {}
    };
  },
  created() {
    this.getList();
    this.queryInfo.condition.agentCode = JSON.parse(
      localStorage.getItem("userInfo")
    ).userInfo.agentCode;
    this.settleQueryInfo.condition.agentCode = JSON.parse(
      localStorage.getItem("userInfo")
    ).userInfo.agentCode;
  },
  methods: {
    back() {
      this.$router.push({ path: '/Welcome' });
    },
    getList() {
      this.$http
        .post("/houseAgentBillSettlement/list", this.queryInfo)
        .then(res => {
          if (res.data.code == 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.count;
          }
        });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    // 修改页数大小
    settleSizeChange(val) {
      this.settleQueryInfo.pageSize = val;
      this.getSettleList();
    },

    // 获取当前页面
    settleCurrentChange(val) {
      this.settleQueryInfo.currPage = val;
      this.getSettleList();
    },
    toSearch() {
      this.getList();
    },
    toReset() {
      this.queryInfo.condition = {};
      this.getList();
    },
    openSettleDialog() {
      this.settlementVisible = true;
      this.getSettleList();
    },
    getSettleList() {
      this.$http
        .post("houseAgentBill/noSettlementList", this.settleQueryInfo)
        .then(res => {
          if (res.data.code == 200) {
            this.settleTableData = res.data.data.data;
            this.settleTotal = res.data.data.count;
          }
        });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
      this.settlementSummary();
    },
    toSettle() {
      this.$confirm("确定提交结算吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let userInfo = JSON.parse(localStorage.getItem("userInfo")).userInfo;
          let data = {
            agentCode: userInfo.agentCode,
            agentName: userInfo.agentName,
            billList: this.multipleSelection
          };
          this.$http
            .post("houseAgentBillSettlement/batchSettlementList", data)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("提交成功！");
                this.settlementVisible = false;
                this.getList();
              }
            });
        })
        .catch(() => {});
    },
    settleSearch() {
      this.getSettleList();
    },
    settlementSummary() {
      let moduleNumTotal = 0;
      let totalPrice = 0;
      this.multipleSelection.forEach(item => {
        moduleNumTotal = moduleNumTotal + item.moduleNum;
        totalPrice = totalPrice + item.payPrice;
      });

      this.moduleNumTotal = moduleNumTotal;
      this.totalPrice = totalPrice;
    },
    deleteCustomerName(index) {
      let row = this.multipleSelection[index];
      let rows = this.settleTableData.find(element => {
        return element.id === row.id;
      });
      this.$refs.multipleTable.toggleRowSelection(rows, false);
    },
    openView(row) {
      this.viewVisible = true;
      this.viewFrom = row;
    },
    toShowInvoice(row) {
      this.invoiceVisible = true;
      this.invoiceId = row.id;
      if (row.invoiceUrl) {
        this.detailList = JSON.parse(row.invoiceUrl);
      }
    },
    handleImageUrlPreview(e, index) {
      e.stopPropagation();
      this.dialogImageUrl = this.detailList[index].imageUrl;
      this.dialogVisible = true;
    },
    handleImageRemove(e, index) {
      e.stopPropagation();
      this.$nextTick(() => {
        this.detailList.splice(index, 1);
      });
    },
    submitInvoice() {
      if (this.detailList.length == 0) {
        this.$message.error("请上传发票图片");
      } else {
        this.$http
          .post("/houseAgentBillSettlement/openInoice", {
            id: this.invoiceId,
            invoiceUrl: this.detailList
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("上传成功！");
              this.invoiceVisible = false;
              this.getList();
            } else {
              this.$message.error(res.data.message);
            }
          });
      }
    },
    toShowInvoice(row) {
      this.invoiceVisible = true;
      this.invoiceId = row.id;
      if (row.invoiceUrl) {
        this.detailList = JSON.parse(row.invoiceUrl);
      }
    },
    //图片上传成功处理
    handleimageSuccess(val) {
      this.detailList.push({
        imageUrl: val.data.url
      });
      this.IULoading = false;
    },
    //图片上传之前处理
    beforeUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      // return isJPG && isLt2M;
      return isLt2M;
    }
  }
};
</script>
<style lang="less" scoped>
.searchBox {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}
.selectedName {
  display: flex;
  margin: 10px 0;
}
.customerNameBox {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  flex: 1;
}
.customerNameItem {
  border: 1px solid #e4e4e4;
  padding: 3px 20px;
  position: relative;
  border-radius: 3px;

  .delete {
    width: 10px;
    height: 10px;
    line-height: 10px;
    background-color: #d71d05;
    border-radius: 50%;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    position: absolute;
    right: -5px;
    top: -5px;
    cursor: pointer;
  }
}
.summaryContent {
  width: 300px;
  padding: 10px 50px;
  border: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-left: 42px;
  border-radius: 5px;
}
.viewTitle {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
}
.viewTitle::after {
  display: block;
  content: "";
  width: 5px;
  height: 20px;
  background-color: #00a5ef;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.viewValue {
  border: 1px solid #e4e4e4;
  // width: 210px;
  padding: 0 10px;
  border-radius: 3px;
  word-wrap: break-word;
}
.viewImg {
  width: 100px;
  height: 100px;
}
.settleAmount {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #8c939d;
  font-size: 11px;
  line-height: 16px;
}
.settleAmount > div {
  margin-right: 8px;
}
.red {
  color: #d71d05;
}
.blue {
  color: #00a5ef;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}

.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.avatar-uploader .el-upload {
  border: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
/deep/.el-loading-spinner {
  margin-top: -74px;
}
.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}
.viewContent {
  max-height: 500px;
  overflow-y: scroll;
}
/deep/.el-card__body {
  padding: 7px;
}
/deep/.el-form-item {
  margin-bottom: 7px;
}
/deep/ .el-form-margin0 {
  .el-form-item {
    margin-bottom: 0px;
  }
}
/deep/ .el-table__body tr.hover-row > td {
  background-color: #f59a23 !important;
}
</style>
