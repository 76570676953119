import { render, staticRenderFns } from "./settlement1.vue?vue&type=template&id=98e4b252&scoped=true"
import script from "./settlement1.vue?vue&type=script&lang=js"
export * from "./settlement1.vue?vue&type=script&lang=js"
import style0 from "./settlement1.vue?vue&type=style&index=0&id=98e4b252&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98e4b252",
  null
  
)

component.options.__file = "settlement1.vue"
export default component.exports